<template>
  <v-navigation-drawer
      app
      dark
      color="header"
      permanent
      :mini-variant="drawer"
      class="draw">
    <v-list class="list" dense>
      <v-list-item class="top pl-0">
        <router-link :to="{name: 'loading'}">
          <img :src="KahuLogo" alt="Kahu">
        </router-link>
        <v-list-item-content/>
        <v-list-item-action class="top-menu">
          <site-menu/>
          <account-menu/>
        </v-list-item-action>
      </v-list-item>
      <v-list-group
          :group="i.group"
          :key="i.title"
          v-for="i in menu">
        <template #activator>
          <v-list-item-icon>
            <v-icon>{{ i.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ i.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group class="sub-menu-item">
          <v-list-item
              :key="link.title"
              :to="link.to"
              link
              v-for="link in i.links">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
            <v-chip v-if="link.badge" small color="accent darken-1" :style="{flexBasis: '35%'}">
              {{ link.badge }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
    <template #append>
      <div class="collapse">
        <v-btn icon class="close-btn" @click="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import KahuLogo from '@/assets/Kahu_letterform_cyan.svg?as-is';
import AccountMenu from '@/components/AccountMenu';
import SiteMenu from '@/components/SiteMenu';
import {mapGetters} from 'vuex';

export default {
  name: 'NavBar',
  components: {SiteMenu, AccountMenu},
  data() {
    return {
      drawer: false
    };
  },
  computed: {
    ...mapGetters('appConfig', [
      'sectionCardsEnabled',
      'sectionDashboardStatisticsEnabled',
      'sectionUsersEnabled',
      'sectionVisitorsEnabled',
      'sectionRoomBookingReadOnly'
    ]),
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapGetters('sites/active', [
      'hasDeskBooking',
      'hasRoomBooking'
    ]),
    ...mapGetters('config', ['logo']),
    ...mapGetters('user', ['getUser']),

    KahuLogo() {
      return (this.logo) ? this.logo : KahuLogo;
    },
    currentUserNs() {
      return this.getUser?.ns;
    },
    adminMenu() {
      const admin = {
        title: 'Admin',
        group: 'admin',
        icon: 'mdi-wrench',
        links: []
      };
      const adminAccess = this.hasAccessTo.admin;

      if (adminAccess.audit) {
        admin.links.push({
          title: 'Audit Logs',
          icon: 'mdi-format-list-checks',
          to: {name: 'admin-audit-logs'}
        });

        if (this.currentUserNs === 'inf') {
          admin.links.push({
            title: 'Audit Sync Report',
            icon: 'mdi-chart-box-outline',
            to: {name: 'admin-audit-sync-report'},
            badge: 'BETA'
          });
        }
      }

      // if (adminAccess.siteConfig) {
      //   admin.links.push({
      //     title: 'Site Config',
      //     icon: 'mdi-tune',
      //     to: {name: 'admin-site-config'}
      //   });
      // }

      if (adminAccess.integrations) {
        admin.links.push({
          title: 'Integrations',
          icon: 'mdi-vector-link',
          to: {name: 'admin-integrations'}
        });
      }
      return admin;
    },
    dashboardMenu() {
      const dashboard = {
        title: 'Dashboard',
        group: 'dashboard',
        icon: 'mdi-gauge',
        links: []
      };

      const ls = this.hasAccessTo.dashboard.liveStatus;
      if (ls.desks || ls.rooms) {
        dashboard.links.push({
          title: 'Live Status',
          icon: 'mdi-chart-donut',
          to: {name: 'dashboard-live-status'}
        });
      }
      if (this.sectionDashboardStatisticsEnabled && this.hasAccessTo.dashboard.stats) {
        dashboard.links.push({
          title: 'Statistics',
          icon: 'mdi-chart-bar',
          to: {name: 'dashboard-statistics'},
          badge: 'BETA'
        });
      }
      return dashboard;
    },
    peopleMenu() {
      const people = {
        title: 'People',
        group: 'people',
        icon: 'mdi-account-group',
        links: []
      };
      const peopleAccess = this.hasAccessTo.people;

      if (peopleAccess.directory) {
        people.links.push({
          title: 'People Directory',
          icon: 'mdi-contacts',
          to: {name: 'directory'}
        });
      }

      if (this.sectionVisitorsEnabled && peopleAccess.visitors) {
        people.links.push({
          title: 'Visitors',
          icon: 'mdi-account-clock',
          to: {name: 'visitors'}
        });
      }
      if (this.sectionCardsEnabled && peopleAccess.cards) {
        people.links.push({
          title: 'Cards',
          icon: 'mdi-badge-account-horizontal',
          to: {name: 'cards'}
        });
      }
      if (this.sectionUsersEnabled && peopleAccess.users) {
        people.links.push({
          title: 'Users',
          icon: 'mdi-account-box-multiple',
          to: {name: 'users'}
        });
      }
      return people;
    },
    deskBookingMenu() {
      const deskBooking = {
        title: 'Desk Booking',
        group: 'desk-booking',
        icon: 'mdi-desk',
        links: []
      };
      if (!this.hasDeskBooking && this.currentUserNs !== 'inf') {
        return deskBooking;
      }
      const deskBookingAccess = this.hasAccessTo.deskBooking;

      // temporarily disable this menu item until it's ready
      if (false && deskBookingAccess.siteSettings) {
        deskBooking.links.push({
          title: 'Site Settings',
          icon: 'mdi-office-building',
          to: {name: 'desk-site-settings'}
        });
      }

      if (deskBookingAccess.bookingsExport) {
        deskBooking.links.push({
          title: 'Desk Bookings Export',
          icon: 'mdi-text-box',
          to: {
            name: 'desk-bookings-export'
          }
        });
      }

      // this.currentUserNs === 'inf' has been added at the request of inf
      // to allow users with access to Workplace to see floorplans
      // https://vanti.youtrack.cloud/issue/INF-320/Show-basic-floorplan-page-in-workplace-to-all-users
      if (deskBookingAccess.deskSettings || deskBookingAccess.neighbourhoodSettings || this.currentUserNs === 'inf') {
        deskBooking.links.push({
          title: 'Desk Settings',
          icon: 'mdi-map-marker-radius',
          to: {
            name: 'desk-settings'
          }
        });
      }

      return deskBooking;
    },
    roomBookingMenu() {
      const roomBooking = {
        title: 'Room Booking',
        group: 'room-booking',
        icon: 'mdi-door-open',
        links: []
      };
      if (!this.hasRoomBooking) {
        return roomBooking;
      }

      if (this.hasAccessTo.roomBooking) {
        const calendar = {
          title: 'Calendar',
          icon: 'mdi-calendar',
          to: {name: 'room-booking-calendar'}
        };
        roomBooking.links.push(calendar);
        const exports = {
          title: 'Export',
          icon: 'mdi-text-box',
          to: {name: 'room-booking-export'}
        };
        roomBooking.links.push(exports);
      }

      return roomBooking;
    },
    menu() {
      const menu = [];
      const admin = this.adminMenu;
      const people = this.peopleMenu;
      const deskBooking = this.deskBookingMenu;
      const roomBooking = this.roomBookingMenu;
      const dashboard = this.dashboardMenu;

      if (people.links.length > 0) {
        menu.push(people);
      }

      if (deskBooking.links.length > 0) {
        menu.push(deskBooking);
      }

      if (roomBooking.links.length > 0) {
        menu.push(roomBooking);
      }

      if (dashboard.links.length > 0) {
        menu.push(dashboard);
      }

      if (admin.links.length > 0) {
        menu.push(admin);
      }

      return menu;
    }
  }
};
</script>

<style scoped>
.sub-menu-item {
  background-color: var(--v-header-darken1);
}

.top {
  max-height: 56px;
}

.top:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: var(--v-grey-lighten3);
  z-index: -1;
}

.top img {
  height: 56px;
  vertical-align: top;
  z-index: 1;
}

.list {
  padding: 0;
}

.v-list-item__action--stack {
  flex-direction: row;
  align-items: center;
  /*
  align the buttons with the expand icon in the nav list. Icons are 24x24 but buttons are 36x36,
  the difference is 12px, so we adjust to the right by half of that.
  */
  margin-right: -6px;
}

.v-list-item__action--stack > :not(:last-child) {
  /* Space between buttons in the action section */
  margin-right: 8px;
}

.draw {
  box-shadow: 6px 1px 15px var(--v-grey-lighten3);
}

.theme--dark.v-list-item.v-list-item--active {
  color: var(--v-header-lighten1) !important;
}

.v-list-group .v-list-group__header.v-list-item .v-list-item__title,
.v-list-group > .v-list-group__header .v-icon,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-grey-lighten3) !important;
}

.v-icon.theme--dark {
  color: inherit;
}

.theme--dark.v-navigation-drawer {
  background-color: var(--v-grey-base);
}

.collapse {
  text-align: right;
  padding: 0 10px 10px 10px;
}

.collapse >>> .v-icon {
  transform: rotate(0);
  transition: transform 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
  color: var(--v-grey-lighten3);
}

.v-navigation-drawer--mini-variant .collapse >>> .v-icon {
  transform: rotate(180deg);
}
</style>

